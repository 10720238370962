// needed for IE
import '@babel/polyfill';
import 'custom-event-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-intl-redux';
import './scss/index.scss';
// eslint-disable-next-line import/no-named-as-default
import App from './js/App';
import { history, store as createStore } from './js/redux/store';
import { actions as initialLoadActions } from './js/redux/ducks/initial-load';
import { preConnectTarget } from './js/util/misc';

const render = () => {
  ReactDOM.render(
    <Provider store={ store }>
      <App history={ history } />
    </Provider>,
    document.getElementById('root'),
  );
  let hideEvent = {};

  hideEvent = new CustomEvent('hide', {
    detail: { backgroundImageURL: store.getState().brand.background ? store.getState().brand.background.default : '' },
  });

  if (hideEvent !== null && document.getElementById('loading') !== null) {
    document.getElementById('loading').dispatchEvent(hideEvent);
  }

  preConnectTarget();
};

const store = createStore(render);
const { initialLoad } = initialLoadActions;

store.dispatch(initialLoad());
