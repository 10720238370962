import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  SdfInput,
  SdfButton,
  SdfSelectItem,
  SdfSelectSimple,
  SdfAlert,
} from '@synerg/react-components';

import { QuestionCollectionError } from '.';
import { constants } from '../channel-collection/channel-collection-constants';

const _ = require('lodash');

const {
  ANSWER1, ANSWER2, ANSWER3, TYPETEXT, TYPEPASSWORD,
} = constants;

const isEmpty = object => _.isEmpty(object);
const QuestionCollectionForm = (props) => {
  const {
    questionCollectionDisplayChallenge,
    setFirstQuestion,
    setSecondQuestion,
    setThirdQuestion,
    firstQuestion,
    secondQuestion,
    thirdQuestion,
    currentlySending,
    saveQNA,
    firstAnswer,
    secondAnswer,
    thirdAnswer,
    setFirstAnswer,
    setSecondAnswer,
    setThirdAnswer,
    firstAnsInlineErrMessage,
    secondAnsInlineErrMessage,
    thirdAnsInlineErrMessage,
    displayErrorPopup,
    intl,
    cancelQuestionCollection,
    isAppView,
  } = props;

  const { questions } = questionCollectionDisplayChallenge;
  const isBlank = str => (str || '').trim().length === 0;

  // prettier-ignore
  const isSubmitDisabled = () => isBlank(firstAnswer) ||
  isBlank(secondAnswer) ||
  isBlank(thirdAnswer) ||
  isEmpty(firstQuestion) ||
  isEmpty(secondQuestion) ||
  isEmpty(thirdQuestion) ||
  !isValidAnswer(firstAnswer) ||
  !isValidAnswer(secondAnswer) ||
  !isValidAnswer(thirdAnswer) ||
  !isBlank(firstAnsInlineErrMessage) ||
  !isBlank(secondAnsInlineErrMessage) ||
  !isBlank(thirdAnsInlineErrMessage) ||
  !isEmpty(firstAnsInlineErrMessage) ||
  !isEmpty(secondAnsInlineErrMessage) ||
  !isEmpty(thirdAnsInlineErrMessage);

  const securityAnsRegex = '[^\\w \\-\\r\\n]{2}|(?:[\\w \\-]+[^\\w \\-\\r\\n]){5}';

  const ansRegex = new RegExp(securityAnsRegex);

  const isValidAnswer = answer => !ansRegex.test(answer);

  const actionOnFocus = () => {
    const activeElement = document.activeElement.id;

    if (activeElement === ANSWER1) {
      document.getElementById(ANSWER1).type = TYPETEXT;
      document.getElementById(ANSWER2).type = TYPEPASSWORD;
      document.getElementById(ANSWER3).type = TYPEPASSWORD;
    }
    if (activeElement === ANSWER2) {
      document.getElementById(ANSWER2).type = TYPETEXT;
      document.getElementById(ANSWER1).type = TYPEPASSWORD;
      document.getElementById(ANSWER3).type = TYPEPASSWORD;
    }
    if (activeElement === ANSWER3) {
      document.getElementById(ANSWER3).type = TYPETEXT;
      document.getElementById(ANSWER2).type = TYPEPASSWORD;
      document.getElementById(ANSWER1).type = TYPEPASSWORD;
    }
  };

  const actionOnBlur = () => {
    document.getElementById(ANSWER1).type = TYPEPASSWORD;
    document.getElementById(ANSWER2).type = TYPEPASSWORD;
    document.getElementById(ANSWER3).type = TYPEPASSWORD;
  };

  const updatedQuestions = index => questions.filter((question) => {
    if (index === 0) {
      return !(
        question.questionId === secondQuestion.questionId ||
          question.questionId === thirdQuestion.questionId
      );
    }
    if (index === 1) {
      return !(
        question.questionId === firstQuestion.questionId ||
          question.questionId === thirdQuestion.questionId
      );
    }
    return !(
      question.questionId === firstQuestion.questionId ||
        question.questionId === secondQuestion.questionId
    );
  });

  const availableSelectItems = index => updatedQuestions(index).map(value => (
    <SdfSelectItem
      label={ value.text }
      key={ value.questionId }
      value={ value.text }
    >
      <div>{ value.text }</div>
    </SdfSelectItem>
  ));

  const questionsConfig = [
    {
      id: '0',
      selectedQuestion: firstQuestion,
      onChangeQuestion: setFirstQuestion,
      errorMessage: firstAnsInlineErrMessage,
      answer: firstAnswer,
      onChangeAnswer: setFirstAnswer,
    },
    {
      id: '1',
      selectedQuestion: secondQuestion,
      onChangeQuestion: setSecondQuestion,
      errorMessage: secondAnsInlineErrMessage,
      answer: secondAnswer,
      onChangeAnswer: setSecondAnswer,
    },
    {
      id: '2',
      selectedQuestion: thirdQuestion,
      onChangeQuestion: setThirdQuestion,
      errorMessage: thirdAnsInlineErrMessage,
      answer: thirdAnswer,
      onChangeAnswer: setThirdAnswer,
    },
  ];

  const getQuestion = detail => questions.find(value => value.text === detail.label);

  const onSubmit = (event) => {
    event && event.preventDefault();
    !currentlySending && saveQNA();
  };

  const errorMessage = questionConfig => questionConfig.errorMessage && questionConfig.errorMessage !== '';

  return !displayErrorPopup ? (
    <div
      id="security-qa"
      className="flex flex-col"
    >
      <form onSubmit={ onSubmit }>
        <div className="mt-2 mb-4 text-left font-medium text-md">
          <FormattedMessage
            id="questionCollection.subtitle"
            defaultMessage="To protect your account, the information you enter will be used to verify your identity if you forget your user ID and/or password."
            description="Question collection page title "
          />
        </div>
        <div className="flex flex-col">
          { questionsConfig.map((questionConfig, index) => (
            <div className="flex flex-col items-center">
              <SdfSelectSimple
                className={
                  isAppView ? 'w-full pb-4' : 'w-full max-w-xs md:max-w-full md:w-3/4 pb-4'
                }
                id={ `question${index + 1}` }
                state="normal"
                label={ intl.formatMessage({
                  id: `questionCollection.securityQuestion${index + 1}`,
                  defaultMessage: `Security Question ${index + 1}`,
                }) }
                placeholder={ intl.formatMessage({
                  id: 'questionCollection.placeholder',
                  defaultMessage: 'questionCollection.placeholder',
                }) }
                valueField="questionId"
                onSdfChange={ eve => questionConfig.onChangeQuestion(getQuestion(eve.detail)) }
              >
                { availableSelectItems(index) }
              </SdfSelectSimple>
              <SdfInput
                id={ `answer${index + 1}` }
                className={
                  isAppView ? 'w-full pb-4' : 'w-full max-w-xs md:max-w-full md:w-3/4 pb-8'
                }
                input-width="full"
                input-width_md={ isAppView ? '' : 'xl' }
                label={ intl.formatMessage({
                  id: `questionCollection.securityAnswer${index + 1}`,
                  defaultMessage: `Security Answer ${index + 1}`,
                }) }
                onSdfInput={ eve => questionConfig.onChangeAnswer(eve.detail) }
                onFocus={ actionOnFocus }
                onBlur={ actionOnBlur }
                state={ errorMessage(questionConfig) ? 'error' : 'normal' }
              >
                { errorMessage(questionConfig) && (
                  <SdfAlert
                    id="errorMessage"
                    type="inline"
                    slot="alert"
                    status="error"
                  >
                    { questionConfig.errorMessage }
                  </SdfAlert>
                ) }
              </SdfInput>
            </div>
          )) }
        </div>
        <div
          className={
            isAppView ?
              'flex flex-row justify-center' :
              'flex flex-col-reverse items-center md:flex-row md:justify-end'
          }
        >
          { !isAppView ? (
            <SdfButton
              id="qnaCancel"
              className={ isAppView ? ' ' : 'w-full max-w-xs md:w-auto mt-4 md:mt-0 pb-12 md:pb-0' }
              emphasis="secondary"
              action="standard"
              onClick={ cancelQuestionCollection }
            >
              <FormattedMessage
                id="questionCollection.cancel"
                defaultMessage="Cancel"
              />
            </SdfButton>
          ) : (
            ''
          ) }

          <SdfButton
            id="submitAnswer"
            className={ isAppView ? 'w-full mt-6' : 'md:pl-4 w-full max-w-xs md:w-auto' }
            emphasis="primary"
            action="standard"
            onClick={ onSubmit }
            onKeyPress={ onSubmit }
            disabled={ isSubmitDisabled() ? true : undefined }
          >
            <FormattedMessage
              id={ isAppView ? 'questionCollection.submit' : 'questionCollection.save' }
              defaultMessage="Submit"
            />
          </SdfButton>
        </div>
      </form>
    </div>
  ) : (
    <QuestionCollectionError { ...props } />
  );
};

QuestionCollectionForm.propTypes = {
  questionCollectionDisplayChallenge: PropTypes.shape({
    questions: PropTypes.instanceOf(Array),
  }),
  questionCollectionDisplaySession: PropTypes.string,
  setFirstQuestion: PropTypes.func,
  setSecondQuestion: PropTypes.func,
  setThirdQuestion: PropTypes.func,
  firstQuestion: PropTypes.shape({
    questionText: PropTypes.string,
  }),
  secondQuestion: PropTypes.shape({
    questionText: PropTypes.string,
  }),
  thirdQuestion: PropTypes.shape({
    questionText: PropTypes.string,
  }),
  currentlySending: PropTypes.bool,
  saveQNA: PropTypes.func.isRequired,
  firstAnswer: PropTypes.string,
  secondAnswer: PropTypes.string,
  thirdAnswer: PropTypes.string,
  setFirstAnswer: PropTypes.func,
  setSecondAnswer: PropTypes.func,
  setThirdAnswer: PropTypes.func,
  cancelQuestionCollection: PropTypes.func,
  firstAnsInlineErrMessage: PropTypes.string,
  secondAnsInlineErrMessage: PropTypes.string,
  thirdAnsInlineErrMessage: PropTypes.string,
  displayErrorPopup: PropTypes.bool,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};
export default injectIntl(QuestionCollectionForm);
