import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { SdfButton, SdfInput } from '@synerg/react-components';
import { isNotBlank, isEnterKeyPressed } from '../../util/misc';
import { constants } from '../login/login-comp-constants';

const { ENTER_KEY_CODE } = constants;

const ChallengeQNA = (props) => {
  const {
    currentlySending,
    verifyChallengeQNA,
    setAnswer,
    answer,
    displayQnAChallenge,
    isAppView,
    onChallengeQnaCancel,
  } = props;
  const isAnswerEntered = !currentlySending && isNotBlank(answer);

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    !currentlySending && isAnswerEntered && verifyChallengeQNA();
  };

  const handleKeyPress = (event) => {
    if (event.charCode === ENTER_KEY_CODE) {
      onSubmit(event);
    }
  };

  return (
    <>
      <div className={ isAppView ? 'mt-2' : 'mt-8 flex justify-center' }>
        <div className={ isAppView ? '' : 'max-w-xs' }>
          <SdfInput
            id="challengeAnswer"
            type="password"
            value={ answer }
            onSdfInput={ eve => setAnswer(eve.detail) }
            onKeyPress={ handleKeyPress }
            input-width="full"
            input-width_sm={ isAppView ? '' : 'xl' }
            className={ isAppView ? 'w-full' : '' }
            label={ displayQnAChallenge.text }
          />
        </div>
      </div>
      <div
        className={
          isAppView ?
            'flex-row flex-1 mt-10 text-center' :
            'flex md:flex-row-reverse mt-20 md:mt-8 flex-col items-center'
        }
      >
        <SdfButton
          id="submitAnswer"
          disabled={ !isAnswerEntered || undefined }
          onClick={ onSubmit }
          onKeyPress={ handleKeyPress }
          emphasis="primary"
          size="lg"
          className={ isAppView ? 'w-full' : 'w-full max-w-xs md:min-w-32 md:w-auto' }
        >
          <FormattedMessage
            id="stepup.verifycode.submit"
            defaultMessage="submit"
          />
        </SdfButton>
        <SdfButton
          id="cancelQNAChallenge"
          emphasis="secondary"
          size="lg"
          className={
            isAppView ? 'w-full pt-4' : 'md:p-4 w-full max-w-xs md:min-w-32 md:w-auto mt-4 md:mt-0'
          }
          onClick={ onChallengeQnaCancel }
          onKeyPress={ (event) => {
            isEnterKeyPressed(event.key) && onChallengeQnaCancel();
          } }
        >
          <FormattedMessage
            id="challenge_qna_cancel"
            defaultMessage="cancel"
          />
        </SdfButton>
      </div>
    </>
  );
};

ChallengeQNA.propTypes = {
  answer: PropTypes.string,
  currentlySending: PropTypes.bool,
  setAnswer: PropTypes.func.isRequired,
  verifyChallengeQNA: PropTypes.func.isRequired,
  displayQnAChallenge: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    questionId: PropTypes.string.isRequired,
  }),
  primaryColorContentText: PropTypes.string,
  isAppView: PropTypes.bool,
};

export default ChallengeQNA;
