import enLocaleData from 'react-intl/locale-data/en';
import deLocaleData from 'react-intl/locale-data/de';
import frLocaleData from 'react-intl/locale-data/fr';
import esLocaleData from 'react-intl/locale-data/es';
import huLocaleData from 'react-intl/locale-data/hu';
import arLocaleData from 'react-intl/locale-data/ar';
import csLocaleData from 'react-intl/locale-data/cs';
import itLocaleData from 'react-intl/locale-data/it';
import jaLocaleData from 'react-intl/locale-data/ja';
import koLocaleData from 'react-intl/locale-data/ko';
import nlLocaleData from 'react-intl/locale-data/nl';
import plLocaleData from 'react-intl/locale-data/pl';
import ptLocaleData from 'react-intl/locale-data/pt';
import ruLocaleData from 'react-intl/locale-data/ru';
import skLocaleData from 'react-intl/locale-data/sk';
import thLocaleData from 'react-intl/locale-data/th';
import zhLocaleData from 'react-intl/locale-data/zh';
import daLocaleData from 'react-intl/locale-data/da';
import fiLocaleData from 'react-intl/locale-data/fi';
import noLocaleData from 'react-intl/locale-data/no';
import roLocaleData from 'react-intl/locale-data/ro';
import svLocaleData from 'react-intl/locale-data/sv';
import trLocaleData from 'react-intl/locale-data/tr';
import viLocaleData from 'react-intl/locale-data/vi';
import inLocaleData from 'react-intl/locale-data/in';
import msLocaleData from 'react-intl/locale-data/ms';
import idLocaleData from 'react-intl/locale-data/id';
import heLocaleData from 'react-intl/locale-data/he';

export const localeData = [
  ...enLocaleData,
  ...deLocaleData,
  ...frLocaleData,
  ...esLocaleData,
  ...huLocaleData,
  ...arLocaleData,
  ...csLocaleData,
  ...itLocaleData,
  ...jaLocaleData,
  ...koLocaleData,
  ...nlLocaleData,
  ...plLocaleData,
  ...ptLocaleData,
  ...ruLocaleData,
  ...skLocaleData,
  ...thLocaleData,
  ...zhLocaleData,
  ...daLocaleData,
  ...fiLocaleData,
  ...noLocaleData,
  ...roLocaleData,
  ...svLocaleData,
  ...trLocaleData,
  ...viLocaleData,
  ...inLocaleData,
  ...msLocaleData,
  ...idLocaleData,
  ...heLocaleData,
];
