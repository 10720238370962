/* eslint-disable global-require */
/* eslint-disable prefer-const */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import {
  SdfInput, SdfCheckbox, SdfButton, SdfQuickStat,
} from '@synerg/react-components';
import {
  setCookie,
  getCookie,
  removeCookie,
  getParameterByName,
  extractBrandingImageProperty,
  getReturnUrl,
  getDevicePrint,
  sleep,
  isLocalStorageSupported,
  removeFidoLocalStorage,
  SECURE,
  SPI_CLIENT_NAME,
  SPI_MOBI_FED_URL,
  setFocusOnInput,
} from '../../util/misc';
import { constants } from './login-comp-constants';
import { ALERT_TYPE_WARNING } from '../../layouts/layout-constants';
import { AlertMessage, LocaleCommonAlert, DynamicMessageAlert } from '..';
import ForgotUserIdLink from '../common/ForgotUserIdLink';
import GreetingsFirst from './GreetingsFirst';

import {
  ADP_LANG_LOCALE_COOKIE,
  DEFAULT_LOCALE,
  getDefaultLocaleFromPreferences,
} from '../../util/locale';
import UserTypeMessage from '../common/UserTypeMessage';
import SPIForm from './SPIForm';

/* TO-DO: CALLING_APP_ID, RETURN_URL, */
const {
  CHALLENGE_TYPE_PASSWORD,
  ENTER_KEY_CODE,
  ALP_COOKIE_REMEMBER_ME,
  ADP_USER_ID_COOKIE,
  COOKIE_REMEMBER_ME,
  DYNATRACE_COOKIE,
  COOKIE_GREET_FNAME,
  CALLING_APP_ID_BROWSER_LABEL,
  POST,
  SLEEP_REFETCH_DEVICEPRINT_IN_MILLISECONDS,
  newUser,
  signDiffUser,
  DYNATRACE_COOKIE_EXPIRY,
  SPINFO_COOKIE,
} = constants;

const isNotBlank = value => value !== undefined && value !== null && value.trim() !== '';

class AppLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.userRef = React.createRef();
    this.previousInputRef = false;
    this.previousUserRef = false;
    this.onChecked = this.onChecked.bind(this);
  }

  componentDidMount() {
    const {
      resetState,
      setUserid,
      userid,
      changeUserFname,
      setRememberCheckBox,
      changeAdpUserId,
      setIsADPUser,
      validationError,
      rememberCheckBox,
      switchToPassword,
      setSwitchPassword,
    } = this.props;

    const isPasswordSwitched = switchToPassword;

    resetState();

    if (isPasswordSwitched) {
      setSwitchPassword(true);
    }

    const rememberUserIdCookie = getCookie(COOKIE_REMEMBER_ME) ?
      getCookie(COOKIE_REMEMBER_ME) :
      getCookie(ALP_COOKIE_REMEMBER_ME);

    changeAdpUserId(rememberUserIdCookie);

    const spiCookieData = getCookie(SPINFO_COOKIE);

    if (spiCookieData) {
      const spInfo = spiCookieData.split('~');

      localStorage.setItem(SPI_CLIENT_NAME, spInfo[0]);
      localStorage.setItem(SPI_MOBI_FED_URL, spInfo[1]);
      removeCookie(SPINFO_COOKIE);
    }

    const userFName = getCookie(COOKIE_GREET_FNAME);

    changeUserFname(userFName);

    if (userid) {
      setRememberCheckBox(rememberCheckBox || !!rememberUserIdCookie);
      if (validationError === 'signin.adpRemember') {
        setIsADPUser(true);
      }
    } else if (rememberUserIdCookie) {
      if (
        rememberUserIdCookie
          .toString()
          .toLowerCase()
          .endsWith('@adp')
      ) {
        setUserid('');
      } else {
        setRememberCheckBox(true);
        setUserid(rememberUserIdCookie.toString());
      }
    }
    const userIdFromExtApp = getCookie(ADP_USER_ID_COOKIE);

    if (userIdFromExtApp) {
      setUserid(userIdFromExtApp);
      removeCookie(ADP_USER_ID_COOKIE);
    }
    let inputEl = document.getElementById('login-form_username');

    inputEl && setFocusOnInput(inputEl);
  }

  componentDidUpdate() {
    if (this.previousUserRef && this.userRef.current) {
      this.userRef.current.setFocus();
      this.previousUserRef = false;
    }

    if (this.previousInputRef && this.inputRef.current) {
      this.inputRef.current.setFocus();
      this.previousInputRef = false;
    }
  }

  onChecked = (val) => {
    const {
      userid, setValidationError, setIsADPUser, setRememberCheckBox,
    } = this.props;

    if (userid && userid.toLowerCase().endsWith('@adp')) {
      setRememberCheckBox(false);
      setIsADPUser(true);
      setValidationError('signin.adpRemember');
    } else {
      setRememberCheckBox(!!val.detail);
      setIsADPUser(false);
      setValidationError('');
    }
    if (!val.detail) {
      removeCookie(COOKIE_REMEMBER_ME);
    }
  };

  render() {
    const {
      userid,
      password,
      currentlySending,
      verifyUserid,
      setUserid,
      useridVerified,
      setPassword,
      authenticate,
      challengeType,
      background,
      productLogo,
      organizationLogo,
      redirectURL,
      identityProviders,
      setValidationError,
      rememberCheckBox,
      isADPUser,
      setIsADPUser,
      setRememberCheckBox,
      locale,
      adpUserId,
      switchToPassword,
      userFName,
      changeAdpUserId,
      changeUserFname,
      changeLocale,
      defaultLocaleMode,
      language,
      formRedirectURL,
      useBrowserIfNoCookie,
      passwordRecovery,
      clearChangePasswordState,
      isAppView,
      dynMsg,
      intl,
      empTargetUrl,
      adminTargetUrl,
    } = this.props;

    const SLEEP_REFETCH_DEVICEPRINT_MS =
      (window.env && window.env.SLEEP_REFETCH_DEVICEPRINT_IN_MILLISECONDS) ||
      SLEEP_REFETCH_DEVICEPRINT_IN_MILLISECONDS;

    const clearReduxState = () => clearChangePasswordState();

    const isValidUserid = !currentlySending && isNotBlank(userid);

    let devicePrint = getDevicePrint();

    const { url: identityRecoveryUrl, version } =
      identityProviders && identityProviders[0].identifierRecovery ?
        identityProviders[0].identifierRecovery :
        '';

    const onVerifyUserid = () => {
      if (devicePrint === '') {
        (async () => {
          await sleep(SLEEP_REFETCH_DEVICEPRINT_MS);
          devicePrint = getDevicePrint();
          userid && !currentlySending && verifyUserid(devicePrint);
        })();
      } else {
        userid && !currentlySending && verifyUserid(devicePrint);
      }
      setCookie(DYNATRACE_COOKIE, userid, SECURE, DYNATRACE_COOKIE_EXPIRY);
    };

    const handleKeyPressOnNexIn = (event) => {
      if (event.charCode === ENTER_KEY_CODE) {
        event.preventDefault();
        onVerifyUserid();
        this.previousInputRef = true;
      }
    };

    if (defaultLocaleMode) {
      const defaultAdpLocaleLangCookie = {
        key: 'AdpLangCookie',
        value: getCookie(ADP_LANG_LOCALE_COOKIE),
      };
      const defaultBrowserLocale = {
        key: 'Browser',
        value: navigator.language || navigator.browserLanguage,
      };
      const defaultBrandingLocale = {
        key: 'branding',
        value: language.default,
      };
      const defaultOlp = { key: 'defaultOLP', value: DEFAULT_LOCALE };
      const defaultLocalePreferences = [
        defaultAdpLocaleLangCookie,
        defaultBrandingLocale,
        defaultOlp,
      ];
      const defaultLocalePreferencesWithBrowser = [
        defaultAdpLocaleLangCookie,
        defaultBrandingLocale,
        defaultBrowserLocale,
        defaultOlp,
      ];
      const defaultLocalePreferencesApp = [defaultAdpLocaleLangCookie, defaultOlp];

      // eslint-disable-next-line no-nested-ternary
      const localeParamsPreferences = isAppView ?
        defaultLocalePreferencesApp :
        useBrowserIfNoCookie ?
          defaultLocalePreferencesWithBrowser :
          defaultLocalePreferences;

      const defaultLocalePrefer = getDefaultLocaleFromPreferences(
        localeParamsPreferences,
        language.options,
      );

      changeLocale(defaultLocalePrefer.value, true);
    }

    const isValidPassword = !currentlySending && isNotBlank(password);

    const onAuthentication = () => {
      clearReduxState();
      !currentlySending && authenticate();
      removeCookie(DYNATRACE_COOKIE);
    };
    const returnURLFromSignInAPI =
      identityProviders && identityProviders.length > 0 ? identityProviders[0].returnUrl : '';
    const challengeTypeExist = !currentlySending && isNotBlank(challengeType);
    const returnURL = getReturnUrl() || returnURLFromSignInAPI;
    const CALLING_APP_ID = getParameterByName(CALLING_APP_ID_BROWSER_LABEL);
    const bgImage = extractBrandingImageProperty(background, locale);
    const productLogoImage = extractBrandingImageProperty(productLogo, locale);
    const organizationLogoImage = extractBrandingImageProperty(organizationLogo, locale);

    const signAsDiffUser = { ...signDiffUser };

    signAsDiffUser.hyperLinkIDPre = signDiffUser.hyperLinkIDPre;
    signAsDiffUser.hyperLinkdefaultMessagePre = signDiffUser.hyperLinkdefaultMessagePre;

    const typeOfUser = userFName ? signAsDiffUser : newUser;

    const { signUp } =
      identityProviders && identityProviders.length > 0 ? identityProviders[0] : '';
    const signUpAlt = { url: signUp && signUp.url, method: POST };
    const signUpObject = signUp && signUp.method ? signUp : signUpAlt;

    const { url: passwordRecoveryUrl, version: passwordRecoveryVersion } = passwordRecovery || '';

    const passwordRecoveryVersionString =
      passwordRecoveryVersion && passwordRecoveryVersion.toString();

    const getVersion = () => {
      if (useridVerified) {
        return isNotBlank(passwordRecoveryVersionString) ? passwordRecoveryVersion : version;
      }
      return version;
    };

    const checkADPUser = () => {
      if (!userid.toLowerCase().endsWith('@adp')) {
        setIsADPUser(false);
        setValidationError('');
      }
      if (userid.toLowerCase().endsWith('@adp') && rememberCheckBox) {
        setIsADPUser(true);
        setRememberCheckBox(false);
      }
    };

    const handleKeyPressOnSignIn = (event) => {
      if (event.charCode === ENTER_KEY_CODE) {
        event.preventDefault();
        clearReduxState();
        userid && isValidPassword && authenticate();
        removeCookie(DYNATRACE_COOKIE);
      }
    };

    const autoFocus = !userid;

    const spiClientName = localStorage.getItem(SPI_CLIENT_NAME);
    const spiMobiFedURL = localStorage.getItem(SPI_MOBI_FED_URL);
    const isSPIFlow =
      isLocalStorageSupported(true) && isNotBlank(spiClientName) && isNotBlank(spiMobiFedURL);

    const isAppReturningUser = isAppView && adpUserId && userFName;

    if (useridVerified) {
      let passwordEl = document.getElementById('login-form_password');

      setFocusOnInput(passwordEl);
    }
    const onSelectDiffUser = () => {
      this.previousUserRef = true;
    };

    return (
      <div>
        { dynMsg && (
        <DynamicMessageAlert
          dynMsg={ dynMsg }
          locale={ locale }
          isAppView={ isAppView }
        />
        ) }
        <div className="min-h-xs mt-4">
          <div>
            <div className="mb-2">
              <img
                src={ productLogoImage }
                alt="Mobile Logo"
                style={ { maxWidth: '200px', maxHeight: '60px' } }
              />
            </div>
            { isAppReturningUser ? (
              <GreetingsFirst
                userid={ userid }
                userFName={ userFName }
                verifyUserid={ verifyUserid }
                useridVerified={ useridVerified }
                switchToPassword={ switchToPassword }
                formattedNumber="(...3456)"
                isAppView={ isAppView }
                isFidoAuthRequired={ false }
              />
            ) : (
              <h2 className="text-4xl font-black my-4">
                <FormattedMessage
                  id="signin_hello"
                  defaultMessage="Hello."
                />
              </h2>
            ) }
          </div>
          <div className={ isAppReturningUser ? 'mb-8' : 'mb-4' }>
            <AlertMessage { ...this.props } />
          </div>
          <div>
            { isSPIFlow ? (
              <SPIForm
                id="spiForm"
                userFName={ userFName }
                clientName={ spiClientName }
                mobiFedURL={ spiMobiFedURL }
                redirectURL={ redirectURL }
              />
            ) : (
              <>
                {!(adpUserId && userFName) || isAppView ? (
                  <div>
                    <div>
                      { isAppReturningUser ? (
                        <SdfQuickStat id="login-form_username">
                          <div slot="label">
                            <FormattedMessage
                              id="signin.userid"
                              defaultMessage="User ID"
                              description="User ID label"
                            />
                          </div>
                          { userid }
                        </SdfQuickStat>
                      ) : (
                        <SdfInput
                          id="login-form_username"
                          value={ userid }
                          label={ intl.formatMessage({
                            id: 'signin.userid',
                            defaultMessage: 'User ID',
                          }) }
                          className="w-full"
                          ref={ this.userRef }
                          onSdfInput={ eve => setUserid(eve.detail) }
                          onKeyPress={ handleKeyPressOnNexIn }
                          onBlur={ checkADPUser }
                          autofocus={ autoFocus }
                          autoComplete="username"
                          disabled={ isAppReturningUser || undefined }
                        />
                      ) }
                    </div>
                    <div className="mt-4 flex">
                      { !isAppReturningUser && (
                        <SdfCheckbox
                          id="user-remember-checkbox"
                          checked={ rememberCheckBox }
                          onSdfChange={ this.onChecked }
                          disabled={ isADPUser || undefined }
                        >
                          <FormattedMessage
                            id={ isAppView ? 'signin.rememberUserId' : 'signin.remember' }
                            defaultMessage="Remember user ID"
                            description="remember me label"
                          />
                        </SdfCheckbox>
                      ) }
                      { !isAppView && (
                        <SdfButton
                          slot="tooltip-target"
                          className="ml-1 text-lg"
                          button-title={ intl.formatMessage({
                            id: 'signin.rememberPopOverText',
                            defaultMessage: 'More Information about remember me',
                            description: 'More Information about remember me label for mouseover',
                          }) }
                          icon="action-help"
                          iconOnly
                          variant="text"
                          id="signin.rememberPopOverText"
                        />
                      ) }
                    </div>
                    { !isAppView &&
                      rememberCheckBox &&
                      !(getCookie(COOKIE_REMEMBER_ME) || getCookie(ALP_COOKIE_REMEMBER_ME)) && (
                        <div
                          className="form-padding"
                          id="frmpublicRememberWarningMessage"
                        >
                          <LocaleCommonAlert
                            autoClose={ false }
                            alertType={ ALERT_TYPE_WARNING }
                            content="signin.publicRemember"
                            id="signin.publicRemember"
                            defaultMessage="Do not store your user ID here if this device is used by others."
                          />
                        </div>
                    ) }
                  </div>
                ) : (
                  <GreetingsFirst
                    userid={ userid }
                    userFName={ userFName }
                    verifyUserid={ verifyUserid }
                    useridVerified={ useridVerified }
                    switchToPassword={ switchToPassword }
                    formattedNumber="(...3456)"
                    isFidoAuthRequired={ false }
                  />
                )}
                {// eslint-disable-next-line max-len
                ((!useridVerified &&
                !(challengeTypeExist && challengeType === CHALLENGE_TYPE_PASSWORD) ?
                  false :
                  isLocalStorageSupported(true)) ||
                  isAppReturningUser) && (
                  <SdfInput
                    id="login-form_password"
                    autoComplete="current-password"
                    autofocus={ useridVerified }
                    ref={ this.inputRef }
                    value={ password }
                    label={ intl.formatMessage({
                      id: 'signin.password',
                      defaultMessage: 'Password',
                    }) }
                    type="password"
                    onSdfInput={ eve => setPassword(eve.detail) }
                    onKeyPress={ handleKeyPressOnSignIn }
                    class="mt-5 w-full"
                  />
                )}
              </>
            ) }
          </div>
        </div>
        <div>
          { isLocalStorageSupported(true) && !userFName && (
            <div
              hidden={ useridVerified || isAppReturningUser }
              className="mt-16 text-center"
            >
              <SdfButton
                id="verifUseridBtn"
                data-testid="verifUseridBtn"
                emphasis="primary"
                size="lg"
                class={ isAppView && 'w-full' }
                disabled={ !isValidUserid || undefined }
                onClick={ onVerifyUserid }
                onKeyPress={ handleKeyPressOnNexIn }
              >
                <span className="px-4 py-3 text-xl">
                  <FormattedMessage
                    id="sigin.next"
                    defaultMessage="Next"
                    description="next button"
                  />
                </span>
              </SdfButton>
            </div>
          ) }
          { isLocalStorageSupported(true) && (
            <div
              hidden={ !useridVerified && !isAppReturningUser }
              className="mt-16 text-center"
            >
              <SdfButton
                id="signBtn"
                data-testid="signBtn"
                emphasis="primary"
                size="lg"
                class={ isAppView && 'w-full' }
                disabled={ !isValidPassword || undefined }
                onClick={ onAuthentication }
                onKeyPress={ handleKeyPressOnSignIn }
              >
                <span className="px-4 py-3 text-xl">
                  <FormattedMessage
                    id="signin.signin"
                    defaultMessage="Sign In"
                    description="signin button"
                  />
                </span>
              </SdfButton>
            </div>
          ) }
          <div className="text-center mt-4 mb-4">
            { isNotBlank(identityRecoveryUrl) && isLocalStorageSupported(true) && !userFName && (
              <div
                id="forgot_uid_link"
                hidden={ useridVerified }
              >
                <ForgotUserIdLink
                  id="forgotUIDLink"
                  key="forgotUIDLink"
                  hyperLinkDescription="Forgot Your UserID?"
                  hyperLinkID={ isAppView ? 'signin.forgotUserId' : 'signin.forgotUid' }
                  hyperLinkdefaultMessage="Forgot Your UserID?"
                  actionURL={ identityRecoveryUrl }
                  callingAppId={ CALLING_APP_ID }
                  backgroundImageUrl={ bgImage }
                  returnUrl={ returnURL }
                  langPref={ isAppView ? locale : '' }
                  oneMobile={ isAppView }
                  olpNative={ isAppView }
                  productLogoUrl={ productLogoImage }
                  organizationLogoUrl={ organizationLogoImage }
                  version={ getVersion() }
                  formRedirectURL={ formRedirectURL }
                  locale={ locale }
                  redirectURL={ redirectURL }
                  ariaLabel="Click to retreive your user id"
                  empTargetUrl={ empTargetUrl }
                  adminTargetUrl={ adminTargetUrl }
                />
              </div>
            ) }
            { isNotBlank(passwordRecoveryUrl) && isLocalStorageSupported(true) && (
              <div
                id="forgot_pwd_link"
                hidden={ !useridVerified }
              >
                <ForgotUserIdLink
                  id="forgotPwdLink"
                  key="forgotPwdLink"
                  hyperLinkDescription="Forgot Your Password?"
                  hyperLinkID={ isAppView ? 'signin.forgotPassword' : 'signin.forgotPwd' }
                  hyperLinkdefaultMessage="Forgot Your Password?"
                  actionURL={ passwordRecoveryUrl }
                  userId={ userid }
                  langPref={ isAppView ? locale : '' }
                  oneMobile={ isAppView }
                  olpNative={ isAppView }
                  callingAppId={ CALLING_APP_ID }
                  backgroundImageUrl={ bgImage }
                  returnUrl={ returnURL }
                  productLogoUrl={ productLogoImage }
                  organizationLogoUrl={ organizationLogoImage }
                  version={ getVersion() }
                  formRedirectURL={ formRedirectURL }
                  locale={ locale }
                  redirectURL={ redirectURL }
                  ariaLabel="Click to retreive your password"
                />
              </div>
            ) }
          </div>
          { isNotBlank(signUpObject.url) && !userFName && (
            <div id="newUsersignUpButton">
              <hr className="border-0 border-t-2 border-solid border-dark" />
              <UserTypeMessage
                id="signupButton"
                key="signupButton"
                signUp={ signUpObject }
                hyperLink={ typeOfUser }
                returnURL={ returnURL }
                callingAppID={ CALLING_APP_ID }
                backgroundImage={ bgImage }
                qrCodeGreet={ removeFidoLocalStorage() }
                productLogo={ productLogoImage }
                setUserid={ setUserid }
                isApp={ isAppView }
                setRememberCheckBox={ setRememberCheckBox }
                organizationLogo={ organizationLogoImage }
                formRedirectURL={ formRedirectURL }
                empTargetUrl={ empTargetUrl }
                adminTargetUrl={ adminTargetUrl }
              />
            </div>
          ) }
          { userFName && (
            <div id="diffUsersButton">
              <hr className="mt-3 mb-6 border-0 border-t-2 border-solid border-dark" />
              <UserTypeMessage
                id="diffUserButton"
                key="diffUserButton"
                hyperLink={ typeOfUser }
                changeAdpUserId={ changeAdpUserId }
                changeUserFname={ changeUserFname }
                userFName={ userFName }
                isApp={ isAppView }
                setRememberCheckBox={ setRememberCheckBox }
                setUserid={ setUserid }
                onSelectDiffUser={ onSelectDiffUser }
                empTargetUrl={ empTargetUrl }
                adminTargetUrl={ adminTargetUrl }
              />
            </div>
          ) }
        </div>
      </div>
    );
  }
}

AppLoginForm.propTypes = {
  userid: PropTypes.string,
  password: PropTypes.string,
  rememberCheckBox: PropTypes.bool,
  isADPUser: PropTypes.bool,
  currentlySending: PropTypes.bool,
  verifyUserid: PropTypes.func.isRequired,
  setUserid: PropTypes.func.isRequired,
  useridVerified: PropTypes.bool,
  setPassword: PropTypes.func.isRequired,
  changeAdpUserId: PropTypes.func,
  setSwitchPassword: PropTypes.func,
  switchToPassword: PropTypes.bool,
  adpUserId: PropTypes.string,
  changeUserFname: PropTypes.func,
  userFName: PropTypes.string,
  clearChangePasswordState: PropTypes.func.isRequired,
  setRememberCheckBox: PropTypes.func.isRequired,
  setIsADPUser: PropTypes.func.isRequired,
  authenticate: PropTypes.func.isRequired,
  isAppView: PropTypes.bool,
  authenticateFido2: PropTypes.func.isRequired,
  challengeType: PropTypes.string,
  useBrowserIfNoCookie: PropTypes.bool,
  resetState: PropTypes.func.isRequired,
  background: PropTypes.shape({
    default: PropTypes.string.isRequired,
  }),
  organizationLogo: PropTypes.shape({
    default: PropTypes.string.isRequired,
  }),
  productLogo: PropTypes.shape({
    default: PropTypes.string.isRequired,
  }),
  redirectURL: PropTypes.func.isRequired,
  identityProviders: PropTypes.arrayOf(
    PropTypes.shape({
      signUp: PropTypes.shape({
        url: PropTypes.string,
        method: PropTypes.string,
      }),
      identifierRecoveryUrl: PropTypes.string,
      identifierRecovery: PropTypes.shape({
        url: PropTypes.string,
        version: PropTypes.number,
      }),
    }),
  ),
  passwordRecovery: PropTypes.shape({
    url: PropTypes.string,
    version: PropTypes.number,
  }),
  validationError: PropTypes.string,
  setValidationError: PropTypes.func.isRequired,
  locale: PropTypes.string,
  changeLocale: PropTypes.func.isRequired,
  formRedirectURL: PropTypes.func.isRequired,
  defaultLocaleMode: PropTypes.bool.isRequired,
  language: PropTypes.shape({
    default: PropTypes.string.isRequired,
  }),
  fidoPromptStatus: PropTypes.string,
  empTargetUrl: PropTypes.string,
  adminTargetUrl: PropTypes.string,
};

AppLoginForm.contextTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AppLoginForm);
