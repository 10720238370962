/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SdfButton, SdfIcon } from '@synerg/react-components';

const QuestionCollectionError = (props) => {
  const { skipQuestionCollection, cancelQuestionCollection } = props;

  return (
    <div
      id="security-qa-server-error"
      className="flex justify-center"
    >
      <form
        className="flex flex-col sm:justify-center"
        onSubmit={ () => {
          skipQuestionCollection();
        } }
      >
        <label className="flex flex-row justify-center content-center text-6xl">
          <SdfIcon icon="alert-warning" />
        </label>
        <h1 className="flex flex-row justify-center content-center">
          <FormattedMessage
            className="flex flex-row justify-center content-center"
            id="questionCollection.serverErrorTitle"
            defaultMessage="Server Error"
          />
        </h1>
        <div
          tabIndex="0"
          className="flex text-center"
        >
          <FormattedMessage
            id="questionCollection.serverErrorText"
            defaultMessage="Security questions could not be saved. We’ll prompt you again the next time you sign in."
          />
        </div>

        <div className="flex flex-row justify-center pt-8 mb-4">
          <SdfButton
            className="w-full max-w-xs md:min-w-32 md:w-auto sm:justify-center"
            id="skipQuestionCollection"
            enphasis="primary"
            onClick={ () => {
              skipQuestionCollection();
            } }
          >
            <FormattedMessage
              id="questionCollection.continue"
              defaultMessage="Continue"
            />
          </SdfButton>
        </div>
        <div
          id="btnCancel"
          className="flex flex-row justify-center"
        >
          <SdfButton
            className="w-full max-w-xs md:min-w-32 md:w-auto sm:justify-center"
            emphasis="tertiary"
            id="cancel-question-collection"
            onClick={ () => {
              cancelQuestionCollection();
            } }
          >
            <SdfIcon
              className="changepassword-close-link"
              icon="action-close"
            />
            { ' ' }
            <FormattedMessage
              id="questionColletion.cancel"
              defaultMessage="CANCEL"
              description="stepup cancel button"
            />
          </SdfButton>
        </div>
      </form>
    </div>
  );
};

QuestionCollectionError.propTypes = {
  skipQuestionCollection: PropTypes.func.isRequired,
  cancelQuestionCollection: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};
export default injectIntl(QuestionCollectionError);
