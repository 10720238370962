/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { SdfButton, SdfIcon } from '@synerg/react-components';
import { getLocaleObjFromPropKey } from '../../util/locale';
import {
  LINK, RIGHT, POP_UP, LINK_POP_UP, CO_BROWSE,
} from './footer-constants';
import FooterPopUpModal from '../common/FooterPopUpModal';
import CoBrowseComponent from '../common/CoBrowseComponent';

const Footer = ({ footer, locale, intl }) => {
  const footerHTML = footer.items
    .sort((elem1, elem2) => (elem1.order < elem2.order ? -1 : 1))
    .map((sortedFooter) => {
      if (getLocaleObjFromPropKey(sortedFooter.component.text, locale)) {
        switch (sortedFooter.component.type) {
          case LINK_POP_UP: {
            const popupWindowWidth = sortedFooter.component.width_size_popup || 800;
            const popupWindowHeight = sortedFooter.component.height_size_popup || 600;
            const url = getLocaleObjFromPropKey(sortedFooter.component.url, locale);

            return (
              <div
                className={ sortedFooter.alignment === RIGHT ? 'md:ml-auto' : '' }
                key={ sortedFooter.order }
              >
                <SdfButton
                  emphasis="tertiary"
                  variant="text"
                  className="text-md md:ml-6 mt-4 md:mt-0"
                  id={ sortedFooter.id }
                  onClick={ () => !window.open(url, '', `width=${popupWindowWidth},height=${popupWindowHeight}`)
                  }
                >
                  { getLocaleObjFromPropKey(sortedFooter.component.text, locale) }
                </SdfButton>
              </div>
            );
          }
          case LINK: {
            const url = getLocaleObjFromPropKey(sortedFooter.component.url, locale);
            const ariaText = sortedFooter.component['alt-text'] ?
              getLocaleObjFromPropKey(sortedFooter.component['alt-text'], locale) :
              '';

            return (
              <div
                className={ sortedFooter.alignment === RIGHT ? 'md:ml-auto' : '' }
                key={ sortedFooter.order }
              >
                <SdfButton
                  id={ sortedFooter.id }
                  variant="text"
                  className="text-md md:ml-6 mt-4 md:mt-0"
                  onClick={ () => !window.open(url, '') }
                >
                  <SdfIcon
                    icon="external-link"
                    icon-title={ ariaText }
                    className="pr-1"
                  />
                  { getLocaleObjFromPropKey(sortedFooter.component.text, locale) }
                </SdfButton>
              </div>
            );
          }
          case POP_UP: {
            return (
              <div
                className={ sortedFooter.alignment === RIGHT ? 'md:ml-auto' : '' }
                key={ sortedFooter.order }
              >
                <FooterPopUpModal
                  displayOrder={ sortedFooter.order }
                  displayText={ sortedFooter.component.text }
                  url={ sortedFooter.component.url }
                  locale={ locale }
                />
              </div>
            );
          }
          case CO_BROWSE: {
            return (
              <div
                className={ sortedFooter.alignment === RIGHT ? 'md:ml-auto' : '' }
                key={ sortedFooter.order }
              >
                <CoBrowseComponent
                  displayOrder={ sortedFooter.order }
                  displayText={ sortedFooter.component.text }
                  locale={ locale }
                  intl={ intl }
                />
              </div>
            );
          }
          default: {
            return (
              <span
                className={ sortedFooter.alignment === RIGHT ? 'my-2 md:my-0 footer-rtl' : '' }
                key={ sortedFooter.order }
              >
                { getLocaleObjFromPropKey(sortedFooter.component.text, locale) }
              </span>
            );
          }
        }
      } else {
        return '';
      }
    });

  return (
    <footer className="md:p-3 bg-action-default-100 border-t border-solid border-dark border-r-0 border-b-0 border-l-0">
      <div className="flex md:pr-2 px-2 md:px-0 text-md flex-col items-center md:flex-row">
        { footerHTML }
      </div>
    </footer>
  );
};

Footer.propTypes = {
  footer: PropTypes.objectOf(PropTypes.array),
  locale: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};

export default Footer;
